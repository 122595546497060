import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  fetchConnectedUserData,
  generateTemplate,
  addEventListeners,
  selectorFactory,
  setStorage,
  setViewerEssentials,
  overrideWithMobileProps,
  ERROR_CODES,
  ERRORS_MESSAGE,
} from '../../utils/Viewer';
import { bi } from '../../bi';
import { WidgetPropsI, LightboxDataType } from '../../types';
import { PresetNames } from '../../enums';

let lightboxData: LightboxDataType = {};

export const onPageReady = async (
  $w,
  flowAPI: PlatformControllerFlowAPI,
  $widget,
) => {
  const { t } = flowAPI.translations;
  const { populateMedia, collapseApp, generateErrorState, initiateElements } =
    selectorFactory($w, flowAPI);
  const { isViewer, isPreview, isMobile } = flowAPI.environment;
  const { preset, mobilePreset } = $widget.props;
  const isGrid =
      ((preset === PresetNames.Grid2x3 && !isMobile) ||
    (mobilePreset === PresetNames.Grid2x3_M && isMobile));
  const mergedProps = { ...$widget.props };
  const props = overrideWithMobileProps(mergedProps, isMobile, isGrid);
  const isViewMode = isViewer || isPreview;
  bi({ flowAPI }).appLoadedStart();
  initiateElements(props);

  try {
    setViewerEssentials(flowAPI);
    const { accountId } = props as WidgetPropsI;
    if (!isViewMode) {
      setStorage(flowAPI, props);
    }

    if (accountId) {
      const { igUser, addedMedias, initialLightboxData } =
        await fetchConnectedUserData(flowAPI, props);
      const { instagramAccount } = igUser;
      const { synchronized } = instagramAccount || {};
      if (!synchronized && !isViewMode) {
        const errorData = {
          details: {
            applicationError: {
              code: ERROR_CODES.INVALID_TOKEN_3,
              description: ERRORS_MESSAGE.PASSWORD_CHANGED,
            },
          },
        };
        generateErrorState(errorData, t, isGrid);
      } else {
        lightboxData = {
          ...lightboxData,
          [igUser.instagramAccount?.id as string]: initialLightboxData,
        };
        populateMedia({ igMedia: addedMedias, igUser, props });
        bi({ flowAPI }).appLoadedEnd(false);
      }
    } else {
      const { templateMedias, templateUser } = await generateTemplate(
        flowAPI,
        props,
      );
      lightboxData = {
        user: templateUser,
        media: templateMedias,
      };
      populateMedia({
        igMedia: templateMedias,
        igUser: templateUser,
        props,
      });

      bi({ flowAPI }).appLoadedEnd(true);
    }

    if (isViewMode) {
      addEventListeners(flowAPI, $w, props, lightboxData);
    }
  } catch (error: any) {
    const errorData = error?.response?.data;
    console.log('===>Collapsing App', { errorData });
    isViewMode ? collapseApp() : generateErrorState(errorData, t, isGrid);
  }
};
